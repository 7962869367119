/**
 * ----------------------------------------------------------------------------
 * react-grid-layout types
 * ----------------------------------------------------------------------------
 * This file is basically a dupe of react-grid-layout's types but placed here
 * to use for our API and explicitly set layout breakpoint names that match
 * react-grid-layout's breakpoint names.
 * ----------------------------------------------------------------------------
 **/
import type { Layout } from 'react-grid-layout';

import { StudioVisualizationType } from '../visualizations';

enum LayoutBreakpoint {
    Base = 'base',
    Large = 'lg',
}
type LayoutBreakpointWidths = 768 | 0;
type LayoutColumns = 1 | 2 | 3 | 4 | 5 | 6;

/**
 * A layout item is a `StudioVisualizationType` or `markdown`.
 * This describes to the dashboard grid what kind of item to render.
 */
enum LayoutItemType {
    /**
     * The only kind of layout item that is owned by the dashboard itself.
     * All other item are a type of `StudioVisualizationType`
     **/
    Markdown = 'markdown',
    Pie = StudioVisualizationType.Pie,
    Timeseries = StudioVisualizationType.Timeseries,
    Table = StudioVisualizationType.Table,
    Counter = StudioVisualizationType.Counter,
}

type LayoutItem = Layout & {
    /** Vizualization or Markdown item data */
    data: {
        /**
         * The visualization id or markdown id. For a visualization-type grid item,
         * this `id` can be safeuly used to fetch a visualization. For `markdown` type,
         * this is a client-generated `nanoid()`, thus a `markdown` type item's `id`
         * will match the root `i` key.
         *
         * **Note**: The root `i` key is a unique identifier for the layout item.
         */
        id: string;
        /** type of layout item, which can be a viz or markdown type */
        type: LayoutItemType | StudioVisualizationType;
        /** Possible value for the layout item. e.g. markdown content */
        value?: string;
    };
};

type LayoutConfig = {
    [LayoutBreakpoint.Base]: LayoutItem[];
    [LayoutBreakpoint.Large]: LayoutItem[];
};

const DEFAULT_LAYOUT_CONFIG: LayoutConfig = {
    lg: [],
    base: [],
};

const BREAKPOINT_WIDTHS_BY_NAME = { lg: 768, base: 0 } satisfies Record<LayoutBreakpoint, LayoutBreakpointWidths>;
const COLS_BY_BREAKPOINT = { lg: 6, base: 1 } satisfies Record<LayoutBreakpoint, LayoutColumns>;
type TotalColumnsPerBreakpoint = (typeof COLS_BY_BREAKPOINT)[keyof typeof COLS_BY_BREAKPOINT];

export type { LayoutBreakpointWidths, LayoutColumns, LayoutConfig, LayoutItem, TotalColumnsPerBreakpoint };
export { BREAKPOINT_WIDTHS_BY_NAME, COLS_BY_BREAKPOINT, DEFAULT_LAYOUT_CONFIG, LayoutBreakpoint, LayoutItemType };
