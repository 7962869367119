import { createMutation } from 'react-query-kit';

import { studioQueriesApi } from '@blockworks/platform/api/research/studio/queries';

const useCreateQuery = createMutation({
    mutationKey: [studioQueriesApi.post.createQuery.cachePrefix],
    mutationFn: studioQueriesApi.post.createQuery,
});

const useUpdateQuery = createMutation({
    mutationKey: [studioQueriesApi.patch.updateQuery.cachePrefix],
    mutationFn: studioQueriesApi.patch.updateQuery,
});

const useDeleteQuery = createMutation({
    mutationKey: [studioQueriesApi.delete.query.cachePrefix],
    mutationFn: studioQueriesApi.delete.query,
});

const queryMutations = {
    createQuery: { use: useCreateQuery },
    updateQuery: { use: useUpdateQuery },
    deleteQuery: { use: useDeleteQuery },
};

export { queryMutations };
