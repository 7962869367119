import type { GetExecutionResponse } from '@blockworks/platform/api/research/studio/execution';

import { StudioVisualizationType, StudioVizConfig } from '../models';

const makePieConfig = <T extends GetExecutionResponse['data']>(_?: T): StudioVizConfig<StudioVisualizationType.Pie> => {
    return {
        type: StudioVisualizationType.Pie,
        options: {
            isDonut: true,
            showPartitionLabels: true,
            legend: { position: 'none' },
            groups: [],
        },
        features: { export: false },
        parameters: {} as any,
    };
};

export { makePieConfig };
