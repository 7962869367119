import { GetAssetsResponse } from '@blockworks/platform/api/research/assets';
import { NO_ASSET_CONFIG } from '@blockworks/platform/api/research/studio/dashboard';
import { Avatar } from '@blockworks/ui/avatar';

const selectAssetOptionsForDashboard = ({ data }: GetAssetsResponse) => {
    return [
        {
            label: 'No Asset',
            value: NO_ASSET_CONFIG.value,
            icon: <Avatar size={16} className="border border-content-deselected" src={NO_ASSET_CONFIG.imageUrl} />,
        },
        ...data.map(asset => ({
            label: asset?.title,
            value: asset?.id,
            icon: <Avatar size={16} src={asset.imageUrl} />,
        })),
    ];
};

export { selectAssetOptionsForDashboard };
