import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const BarChart2Icon = forwardRef(
    ({ title, titleId, color, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 17"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
                color,
            })}
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.25}
                d="M6 14.5H3.067c-.374 0-.56 0-.703-.073a.67.67 0 0 1-.291-.291C2 13.993 2 13.806 2 13.433V3.567c0-.374 0-.56.073-.703a.67.67 0 0 1 .291-.291c.143-.073.33-.073.703-.073h1.866c.374 0 .56 0 .703.073a.67.67 0 0 1 .291.291c.073.143.073.33.073.703v1.6M6 14.5h4m-4 0V5.167m0 0h2.933c.374 0 .56 0 .703.072a.67.67 0 0 1 .291.292c.073.142.073.329.073.702V14.5m0-6.667h2.933c.374 0 .56 0 .703.073a.67.67 0 0 1 .291.291c.073.143.073.33.073.703v4.533c0 .374 0 .56-.073.703a.67.67 0 0 1-.291.291c-.143.073-.33.073-.703.073H10"
            />
        </svg>
    ),
);
BarChart2Icon.displayName = 'BarChart2Icon';
export default BarChart2Icon;
