import type { GetExecutionResponse } from '@blockworks/platform/api/research/studio/execution';

import { StudioVisualizationType } from '../models';

import { makeChartConfig } from './make-chart-config';
import { makeCounterConfig } from './make-counter-config';
import { makePieConfig } from './make-pie-config';
import { makeTableConfig } from './make-table-config';

type InitVizConfigProps = {
    type: StudioVisualizationType;
    lastSuccessfulExecution: GetExecutionResponse['data'];
};

const initVizConfig = ({ lastSuccessfulExecution, type }: InitVizConfigProps): any => {
    let initialConfig: any = {};
    switch (type) {
        case StudioVisualizationType.Timeseries:
            initialConfig = makeChartConfig(lastSuccessfulExecution);
            break;
        case StudioVisualizationType.Table:
            initialConfig = makeTableConfig(lastSuccessfulExecution);
            break;
        case StudioVisualizationType.Counter:
            initialConfig = makeCounterConfig(lastSuccessfulExecution);
            break;
        case StudioVisualizationType.Pie:
            initialConfig = makePieConfig(lastSuccessfulExecution);
            break;
        default:
            initialConfig = { type: 'timeseries', groupBy: '' };
    }
    return JSON.stringify(initialConfig);
};

export { initVizConfig };
