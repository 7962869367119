import type { Hit, SearchResponse } from '@algolia/client-search';

import { defineEndpoints } from '@blockworks/platform/api';

import type { Article, Flashnote } from '../../strapi/models/entities.model';
import type { QueryAlgoliaOptions } from '../client/utils/query-algolia.model';

enum AlgoliaContext {
    Research = 'research',
    Editorial = 'editorial',
    StudioQueryTables = 'studio_tables',
}

type AlgoliaResponse<T> = SearchResponse<Hit<T>>;

type GetAlgoliaResearchPostData = (Flashnote | Article) & { kind?: 'flashnote' | 'article' };
type GetAlgoliaResearchPostResponse = AlgoliaResponse<GetAlgoliaResearchPostData>;
type GetAlgoliaResearchPostRequest = {
    sortByDate?: boolean;
} & Omit<QueryAlgoliaOptions, 'contextConfig'>;

type GetAlgoliaEditorialPostData = {
    post_id: number;
    post_type: string;
    post_type_label: string;
    post_title: string;
    post_excerpt: string;
    post_date: number;
    post_author: {
        user_id: number;
        display_name: string;
        slug: string;
        image_url: string;
    };
    images: {
        thumbnail: {
            url: string;
            width: number;
            height: number;
        };
    };
    taxonomies: {
        category: string[];
        post_tag: string[];
        author: string[];
    };
    post_date_formatted: string;
    post_modified: number;
    comment_count: number;
    menu_order: number;
    permalink: string;
    slug: string;
    post_mime_type: string;
    is_sticky: number;
    content: string;
    record_index: number;
};

type GetAlgoliaEditorialPostRequest = Omit<QueryAlgoliaOptions, 'contextConfig'>;
type GetAlgoliaEditorialPostResponse = AlgoliaResponse<GetAlgoliaEditorialPostData>;

type GetAlgoliaStudioTableData = {
    tableName: string;
    tableSchema: string;
    tableColumns: string[];
};

type GetAlgoliaStudioTableRequest = Omit<QueryAlgoliaOptions, 'contextConfig'>;
type GetAlgoliaStudioTableResponse = AlgoliaResponse<GetAlgoliaStudioTableData>;

const ALGOLIA_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        SEARCH: template('/api/algolia/{context}/search'),
    },
}));

enum AlgoliaIndex {
    Research = 'research',
    ResearchSortedByDate = 'research_sorted_by_published_date_desc',
    EditorialPosts = 'wp_posts_post',
    StudioQueryTables = 'studio_tables',
}

export type {
    GetAlgoliaEditorialPostData,
    GetAlgoliaEditorialPostRequest,
    GetAlgoliaEditorialPostResponse,
    GetAlgoliaResearchPostData,
    GetAlgoliaResearchPostRequest,
    GetAlgoliaResearchPostResponse,
    GetAlgoliaStudioTableData,
    GetAlgoliaStudioTableRequest,
    GetAlgoliaStudioTableResponse,
};
export { ALGOLIA_ENDPOINTS, AlgoliaContext, AlgoliaIndex };
