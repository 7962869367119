import { NO_ASSET_CONFIG } from '@blockworks/platform/api/research/studio/dashboard';

const setAssetOrImage = (assetId: string | null) => {
    if (assetId === NO_ASSET_CONFIG.value) {
        return { imageUrl: NO_ASSET_CONFIG.imageUrl, assetId: NO_ASSET_CONFIG.reset };
    }
    return { assetId, imageUrl: '' };
};

export { setAssetOrImage };
