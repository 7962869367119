import { StrictOrderDirection } from './type-helpers';

type FirstPart<S extends string> = S extends `${infer First},${string}` ? First : never;
type FormatOrderParamReturn<T extends string> = `${FirstPart<T>},${StrictOrderDirection}`;

/** Prepares the `order` param for our PHP backend API */
const formatOrderParam = <T extends string>(order: T | undefined): FormatOrderParamReturn<T> | undefined => {
    if (!order || !order?.includes(',')) return;

    const orderParams = order?.split(',');
    const key = orderParams?.[0];
    const direction = orderParams?.[1]?.toUpperCase();

    if (!key && !direction) return;

    return `${key},${direction}` as FormatOrderParamReturn<T>;
};

export { formatOrderParam };
