import { useState } from 'react';
import { StudioButton } from '@studio/components/common/button';
import {
    CreateDashboardModal,
    CreateQueryModal,
    CreateVisualizationModal,
} from '@studio/components/common/create-entity-modals';
import { CreateEntityModalConsumerProps } from '@studio/components/common/create-entity-modals/create-entity-modal';

import { useBoolean } from '@blockworks/platform/hooks';
import { Menu, Popover } from '@blockworks/ui/components';
import { BarChart2Icon, CodeIcon, DashboardIcon, PlusCircleIcon } from '@blockworks/ui/icon';
import { callWith } from '@blockworks/ui/utils';

type EntityType = 'query' | 'dashboard' | 'visualization';
type EntityModalTypes = typeof CreateQueryModal | typeof CreateDashboardModal | typeof CreateVisualizationModal;
type ModalConfig = {
    Modal: EntityModalTypes;
    props: CreateEntityModalConsumerProps | Parameters<EntityModalTypes>[0];
};

const EntityModalsByType: Record<EntityType, ModalConfig> = {
    query: { Modal: CreateQueryModal, props: { hideTrigger: true } },
    dashboard: { Modal: CreateDashboardModal, props: { hideTrigger: true } },
    visualization: { Modal: CreateVisualizationModal, props: { showQuerySelector: true, hideTrigger: true } },
};

const CreateEntityPopover = () => {
    const entityModal = useBoolean(false);
    const createEntityPopover = useBoolean(false);
    const [entityType, setEntityType] = useState<EntityType>('query');

    const { Modal, props = {} } = EntityModalsByType[entityType];

    const handleSetEntityType = (type: EntityType) => {
        setEntityType(type);
        entityModal.setTrue();
        createEntityPopover.setFalse();
    };

    return (
        <>
            <Popover
                trigger={
                    <StudioButton leadingIcon={PlusCircleIcon} variant="fill" intent="primary-soft">
                        Create
                    </StudioButton>
                }
                open={createEntityPopover.value}
                onOpenChange={createEntityPopover.setValue}
                className="rounded-md p-2"
                contentProps={{ align: 'start', side: 'bottom' }}
            >
                <Menu orientation="vertical" className="w-[150px]">
                    <Menu.List>
                        <Menu.Item icon={DashboardIcon} onClick={callWith(handleSetEntityType, 'dashboard')}>
                            Dashboard
                        </Menu.Item>
                        <Menu.Item icon={BarChart2Icon} onClick={callWith(handleSetEntityType, 'visualization')}>
                            Visualization
                        </Menu.Item>
                        <Menu.Item icon={CodeIcon} onClick={callWith(handleSetEntityType, 'query')}>
                            Query
                        </Menu.Item>
                    </Menu.List>
                </Menu>
            </Popover>
            <Modal {...entityModal} {...props} />
        </>
    );
};

export { CreateEntityPopover };
