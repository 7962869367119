import React from 'react';
import { queryMutations } from '@studio/api/query/mutations';
import { StudioRoutePath } from '@studio/models/routes';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { useBoolean } from '@blockworks/platform/hooks';
import { Form } from '@blockworks/ui/forms/form';

import { CreateEntityModal, type CreateEntityModalConsumerProps } from '../create-entity-modal';

const createQueryScema = yup.object({
    title: yup.string().required('A title is required.'),
});

const CreateQueryModal = ({ hideTrigger = false, ...controlledModal }: CreateEntityModalConsumerProps) => {
    const uncontrolledModal = useBoolean(false);
    const modal = controlledModal.value !== undefined ? controlledModal : uncontrolledModal;

    const router = useRouter();
    const { control, handleSubmit, reset, formState } = Form.use({
        resolver: Form.resolvers.yup(createQueryScema),
    });
    const mutation = queryMutations.createQuery.use();
    const onSubmit = handleSubmit(async ({ title }) => {
        try {
            const res = await mutation.mutateAsync({
                title,
                sql: '',
            });
            if (res?.data?.id) {
                modal.setFalse();
                router.push(StudioRoutePath.EditQuery({ id: res.data.id }));
            } else {
                console.error('ID was not returned from create-query:', res);
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    });

    const handleOnClose = () => reset({ title: '' });

    return (
        <>
            <CreateEntityModal.Trigger hide={hideTrigger} onClick={modal.setTrue}>
                New Query
            </CreateEntityModal.Trigger>
            <CreateEntityModal
                open={modal.value}
                title="New Query"
                onOpenChange={modal.setValue}
                errorMessage={mutation?.error?.message}
                submitProps={{
                    loading: formState.isSubmitting,
                    loadingText: 'Creating Query',
                    label: 'Create Query',
                    onSubmit: onSubmit,
                }}
                onClose={handleOnClose}
            >
                <Form.Input label="Title" name="title" control={control} helpText="Give your query a title." />
            </CreateEntityModal>
        </>
    );
};

export { CreateQueryModal };
