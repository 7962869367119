import { startCase } from 'lodash';
import * as yup from 'yup';

import { StudioVisualizationType } from '@blockworks/platform/api/research/studio/visualizations';

const vizTypeSchema = yup.string().oneOf(Object.values(StudioVisualizationType));

const VISUALIZATION_TYPE_FILTER_OPTIONS = Object.values(StudioVisualizationType).map(type => ({
    value: type,
    label: startCase(type),
}));

export { VISUALIZATION_TYPE_FILTER_OPTIONS, vizTypeSchema };
