import { createQuery } from 'react-query-kit';

import { STALE_TIME } from '@blockworks/platform/api';
import { assetsApi, type GetAssetsRequest, type GetAssetsResponse } from '@blockworks/platform/api/research/assets';

import { selectAssetOptionsForDashboard } from './asset-queries.selectors';

const useGetAssets = createQuery<GetAssetsResponse, GetAssetsRequest>({
    queryKey: [assetsApi.get.assets.cachePrefix],
    fetcher: assetsApi.get.assets,
    staleTime: STALE_TIME.$1Minute,
});

const assetQueries = {
    getAssets: {
        use: useGetAssets,
        select: {
            optionsForDashboard: selectAssetOptionsForDashboard,
        },
    },
};

export { assetQueries };
