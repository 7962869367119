import { createQuery } from 'react-query-kit';

import { STALE_TIME } from '@blockworks/platform/api';
import { studioExecutionApi } from '@blockworks/platform/api/research/studio/execution';

const useGetExecutions = createQuery({
    queryKey: [studioExecutionApi.get.executions.cachePrefix],
    fetcher: studioExecutionApi.get.executions,
    staleTime: STALE_TIME.$1Minute,
});

const executionQueries = {
    getExecutions: { use: useGetExecutions },
};

export { executionQueries };
