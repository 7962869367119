import { createMutation } from 'react-query-kit';

import {
    PatchUpdateDashboardRequest,
    PatchUpdateDashboardResponse,
    PostCreateDashboardRequest,
    PostCreateDashboardResponse,
    studioDashboardApi,
} from '@blockworks/platform/api/research/studio/dashboard';

import { setAssetOrImage } from './dashboard-mutations.utils';

const useCreateDashboard = createMutation<PostCreateDashboardResponse, PostCreateDashboardRequest>({
    mutationKey: [studioDashboardApi.post.createDashboard.cachePrefix],
    mutationFn: ({ assetId, ...data }) =>
        studioDashboardApi.post.createDashboard({
            ...data,
            ...setAssetOrImage(assetId),
        }),
});

const useUpdateDashboard = createMutation<PatchUpdateDashboardResponse, PatchUpdateDashboardRequest>({
    mutationKey: [studioDashboardApi.patch.updateDashboard.cachePrefix],
    mutationFn: ({ assetId, ...data }) =>
        studioDashboardApi.patch.updateDashboard({
            ...data,
            ...(assetId ? setAssetOrImage(assetId) : {}),
        }),
});

const dashboardMutations = {
    createDashboard: { use: useCreateDashboard },
    updateDashboard: { use: useUpdateDashboard },
};

export { dashboardMutations };
