import React from 'react';

import { useBoolean } from '@blockworks/platform/hooks';
import { Alert } from '@blockworks/ui/alert';
import { Form } from '@blockworks/ui/forms/form';
import { PlusIcon } from '@blockworks/ui/icon';
import { Modal, ModalProps } from '@blockworks/ui/modal';

import { StudioButton } from '../button';

type CreateEntityModalProps = React.PropsWithChildren<
    {
        title: string;
        errorMessage?: string;
        submitProps: {
            loading?: boolean;
            loadingText?: string;
            label?: string;
            disabled?: boolean;
            onSubmit: (e: React.BaseSyntheticEvent) => Promise<unknown>;
        };
        onOpenChange: NonNullable<ModalProps['onOpenChange']>;
    } & Pick<ModalProps, 'open' | 'onClose'>
>;

/** A wrapped modal that handles form submission for creating a new entity e.g. a dashboard, a query */
const CreateEntityModal = ({
    open,
    onOpenChange,
    title,
    onClose,
    submitProps,
    errorMessage,
    children,
}: CreateEntityModalProps) => {
    const { label, onSubmit, ...restSubmitProps } = submitProps;
    return (
        <Modal
            open={open}
            onOpenChange={onOpenChange}
            onClose={onClose}
            classNames={{ base: 'md:w-[500px] md:max-w-[700px] w-[85vw] md:h-[425px] md:max-h-[500px] h-[85vh]' }}
        >
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="gap-4">
                    {children}
                    {errorMessage && (
                        <Alert intent="danger" size="sm" fontSize="xs" mt="auto">
                            {errorMessage}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit size="2sm" type="button" borderRadius="md" onClick={onSubmit} {...restSubmitProps}>
                        {label}
                    </Form.Submit>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    );
};

type CreateEntityModalTriggerProps = React.PropsWithChildren<{
    onClick: () => void;
    hide?: boolean;
}>;

const CreateEntityModalTrigger = ({ onClick, children, hide = false }: CreateEntityModalTriggerProps) => {
    if (hide) return null;
    return (
        <StudioButton intent="primary" variant="fill" trailingIcon={PlusIcon} onClick={onClick}>
            {children}
        </StudioButton>
    );
};

CreateEntityModal.Trigger = CreateEntityModalTrigger;

type ModalState = ReturnType<typeof useBoolean>;
type CreateEntityModalConsumerProps = (ModalState | Partial<Record<keyof ModalState, never>>) & {
    hideTrigger?: boolean;
};

export type { CreateEntityModalConsumerProps };
export { CreateEntityModal };
