import { GetAlgoliaStudioTableResponse } from '@blockworks/platform/api/algolia';

const selectTableSchema = (res: GetAlgoliaStudioTableResponse) => {
    const tables: Record<string, Record<string, string[]>> = {};

    res.hits?.forEach(entry => {
        const schema = entry.tableSchema;
        const tableName = entry.tableName;
        const columns = entry.tableColumns;

        if (!tables[schema]) {
            tables[schema] = {};
        }

        tables[schema][tableName] = columns;
    });

    return tables;
};

const selectTables = (res: GetAlgoliaStudioTableResponse) => {
    if (!Array.isArray(res.hits)) {
        return { items: [], totalCount: 0 };
    }
    const tables = res.hits.reduce(
        (acc, { tableSchema, tableName }) => {
            if (tableSchema && tableName) {
                acc.push({
                    tableName: `${tableSchema}.${tableName}`,
                    tableSchema,
                });
            }
            return acc;
        },
        [] as Array<{ tableName: string; tableSchema: string }>,
    );
    return {
        items: tables,
        totalCount: tables.length,
    };
};

export { selectTables, selectTableSchema };
