import { createQuery } from 'react-query-kit';
import { selectTables, selectTableSchema } from '@studio/api/query/queries/query-queries.selectors';
import { QueryClient } from '@tanstack/react-query';

import { STALE_TIME } from '@blockworks/platform/api';
import { algoliaApi } from '@blockworks/platform/api/algolia';
import { StudioExecutionState } from '@blockworks/platform/api/research/studio/execution';
import { GetQueryResponse, studioQueriesApi } from '@blockworks/platform/api/research/studio/queries';

const useGetQueries = createQuery({
    queryKey: [studioQueriesApi.get.queries.cachePrefix],
    fetcher: studioQueriesApi.get.queries,
    staleTime: STALE_TIME.$3Minutes,
});

const useGetQuery = createQuery({
    queryKey: [studioQueriesApi.get.query.cachePrefix],
    fetcher: studioQueriesApi.get.query,
    staleTime: STALE_TIME.$1Minute,
});

const useGetQueryTables = createQuery({
    queryKey: [algoliaApi.get.studioQueryTables.cachePrefix],
    fetcher: algoliaApi.get.studioQueryTables,
    staleTime: STALE_TIME.$3Minutes,
});

type MutableExecutionData = {
    /** the id for the execution that was just created */
    lastExecutionId: GetQueryResponse['data']['lastExecutionId'];
    /**
     * Only allow `pending` state to be set because the BE api contract for when a query is executed,
     * sets the execution state to `pending`
     */
    executionState: Extract<GetQueryResponse['data']['executionState'], StudioExecutionState.Pending>;
};

/**
 * A cache utility to save the client from needing to refetch/invalidate the query data,
 * with data we should already have returned from the `POST` execution request
 **/
const setQueryCacheLastExecutionData = (client: QueryClient, queryId: string, newData: MutableExecutionData) => {
    client.setQueryData(useGetQuery.getKey({ id: queryId }), old => {
        if (!old) return old;

        return {
            ...old,
            data: {
                ...old.data,
                ...newData,
            },
        };
    });
};

const queryQueries = {
    getQueries: { use: useGetQueries },
    getQuery: { use: useGetQuery, cache: { setLastExecutionData: setQueryCacheLastExecutionData } },
    getQueryTables: { use: useGetQueryTables, select: { selectTableSchema, selectTables } },
};

export { queryQueries };
