import { createMutation } from 'react-query-kit';

import { studioVisualizationsApi } from '@blockworks/platform/api/research/studio/visualizations';

const useCreateVisualization = createMutation({
    mutationKey: [studioVisualizationsApi.post.createVisualization.cachePrefix],
    mutationFn: studioVisualizationsApi.post.createVisualization,
});

const useUpdateVisualization = createMutation({
    mutationKey: [studioVisualizationsApi.patch.updateVisualization.cachePrefix],
    mutationFn: studioVisualizationsApi.patch.updateVisualization,
});

const useDeleteVisualization = createMutation({
    mutationKey: [studioVisualizationsApi.delete.visualization.cachePrefix],
    mutationFn: studioVisualizationsApi.delete.visualization,
});

const visualizationMutations = {
    createVisualization: { use: useCreateVisualization },
    updateVisualization: { use: useUpdateVisualization },
    deleteVisualization: { use: useDeleteVisualization },
};

export { visualizationMutations };
