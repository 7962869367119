import { tryJsonParse } from '../../../../utils';
import { StudioVisualizationType } from '../visualizations';

import { DEFAULT_LAYOUT_CONFIG, type LayoutConfig } from './dashboard-layout.model';

const getSafeLayoutConfig = (layoutConfigStr: string): LayoutConfig => {
    const { parsedJson } = tryJsonParse<LayoutConfig>(layoutConfigStr);
    if (!parsedJson) return DEFAULT_LAYOUT_CONFIG;

    if (!parsedJson.lg) {
        parsedJson['lg'] = [];
    }

    if (!parsedJson.base) {
        parsedJson['base'] = [];
    }

    return parsedJson;
};

const VIZ_TYPES = Object.values(StudioVisualizationType);
const layoutItemIsViz = (value: any): value is StudioVisualizationType => {
    return VIZ_TYPES.includes(value);
};

export { getSafeLayoutConfig, layoutItemIsViz };
