import { StudioVizCounterConfig } from './counter-config';
import { StudioVizPieConfig } from './pie-config';
import { StudioVizTableConfig } from './table-config';
import { StudioVizTimeseriesConfig } from './time-series-config';

enum StudioVisualizationType {
    Timeseries = 'timeseries',
    Pie = 'pie',
    Counter = 'counter',
    Table = 'table',
}

interface StudioVisualizationParameterSettings {
    enabled: boolean;
    initialValue: string;
    excludes: string[];
}

type GetConfigOptionsByType<T extends StudioVisualizationType> = T extends StudioVisualizationType.Table
    ? StudioVizTableConfig
    : T extends StudioVisualizationType.Counter
      ? StudioVizCounterConfig
      : T extends StudioVisualizationType.Pie
        ? StudioVizPieConfig
        : StudioVizTimeseriesConfig<any>;

type StudioVizConfig<T extends StudioVisualizationType = StudioVisualizationType.Timeseries> = {
    type: StudioVisualizationType;
    options: GetConfigOptionsByType<T>;
    features?: { export?: boolean };
    parameters: Record<string, StudioVisualizationParameterSettings>;
};

export type { StudioVizConfig };
export { StudioVisualizationType };
