import { eachMinuteOfInterval, eachYearOfInterval } from 'date-fns';
import { first, last, orderBy, pickBy, uniqBy } from 'lodash';

import {
    differenceInSeconds,
    eachDayOfInterval,
    eachHourOfInterval,
    eachMonthOfInterval,
    eachQuarterOfInterval,
    eachWeekOfInterval,
    fromUnixTime,
    intervalToDuration,
} from '../../../../libs/date-fns';

import {
    ExecutionIntervalMeta,
    type GetExecutionResponse,
    STUDIO_EXECUTION_COL_TYPE_MAP,
    StudioExecutionColumnTypes,
} from './execution.model';

const aggregateTimeframes = ['minutes', 'hours', 'days', 'weeks', 'months', 'quarters'];

const INTERVAL_BOUNDS = {
    minutes: { getChunks: eachMinuteOfInterval, min: 60, max: 60 * 24 * 7, format: 'hourly', label: 'Mn' },
    hours: {
        getChunks: eachHourOfInterval,
        min: 24,
        format: 'hourly',
        label: 'H',
    },
    days: {
        getChunks: eachDayOfInterval,
        min: 7,
        format: 'daily',
        label: 'D',
    },
    weeks: {
        getChunks: (args: Parameters<typeof eachWeekOfInterval>[0]) => eachWeekOfInterval(args, { weekStartsOn: 1 }),
        min: 4,
        format: 'weekly',
        label: 'W',
    },
    months: {
        getChunks: eachMonthOfInterval,
        min: 2,
        format: 'monthly',
        label: 'M',
    },
    quarters: {
        getChunks: eachQuarterOfInterval,
        min: 2,
        format: 'quarterly',
        label: 'Q',
    },
    years: {
        getChunks: eachYearOfInterval,
        min: 4,
        format: 'monthly',
        label: 'Y',
    },
} as const;

const getAvailableAggregates = ({
    unit,

    start: startDate,
    end: endDate,
}: {
    unit: 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years';
    start: Date;
    end: Date;
}) => {
    const start = aggregateTimeframes.indexOf(unit);
    const testAggregates = aggregateTimeframes.slice(start, aggregateTimeframes.length);
    const aggregates: { label: string; value: string }[] = [];
    for (const view of testAggregates) {
        const { min, label, getChunks } = INTERVAL_BOUNDS[view as typeof unit];
        const numberOfChunks = getChunks({ start: startDate, end: endDate });
        if (numberOfChunks.length > min) {
            aggregates.push({
                value: view,
                label: label,
            });
        }
    }
    return aggregates;
};

const possibleDateTypes = ['date', 'TEXT', 'TIMESTAMP WITH TIME ZONE'];

const getExecutionViews = (execution: GetExecutionResponse['data']) => {
    const dimensions: Record<string, ExecutionIntervalMeta> = {};

    if (!execution.metadata?.columnTypes) {
        return { type: 'ordinal', dimensions };
    }
    const timeDimensions =
        Object.keys(pickBy(execution.metadata.columnTypes, type => possibleDateTypes.includes(type))) ?? [];
    const { rows } = execution;

    if (timeDimensions.length === 0) return { type: 'ordinal', dimensions };

    try {
        for (const timeCol of timeDimensions) {
            const uniqueTimes = orderBy(uniqBy(rows, timeCol), timeCol);

            const count = uniqueTimes.length;
            const isMultiRow = count < rows.length;
            const intervalDuration = intervalToDuration({
                start: new Date(uniqueTimes[0]?.[timeCol]),
                end: new Date(uniqueTimes[1]?.[timeCol]),
            });

            const [unit, duration] = Object.entries(intervalDuration).find(([_, value]) => Math.abs(value) >= 1) ?? [];
            // If no intervals, skip
            if (!unit || !duration) continue;

            const normalizedDuration = duration === 7 && unit === 'days' ? 1 : Math.abs(duration);
            const normalizedUnit = unit === 'days' && duration === 7 ? 'weeks' : unit;

            // If invalid basis, skip
            if (!['hours', 'minutes', 'days', 'months'].includes(normalizedUnit)) continue;

            const start = first(uniqueTimes)?.[timeCol];
            const end = last(uniqueTimes)?.[timeCol];

            // If for somereason we don't have a start or end date, skip
            if (!start || !end) continue;

            dimensions[timeCol] = {
                column: timeCol,
                groupBy: isMultiRow ? Object.keys(execution.metadata.columnTypes) : [],
                unit: normalizedUnit,
                duration: count,
                basis: normalizedDuration,
                aggregateViews: getAvailableAggregates({
                    unit: normalizedUnit as any,
                    start,
                    end,
                }),
            };
        }
        return { type: 'timeseries', dimensions };
    } catch (e) {
        return { type: 'ordinal', dimensions };
    }
};

const getTimeseriesColumn = (execution: GetExecutionResponse['data']) => {
    const { columnKeys, columnTypes } = execution.metadata;
    return columnKeys.find(column => columnTypes?.[column]?.includes('date'));
};

const createColumnRegistry = (execution: GetExecutionResponse['data']) => {
    const { metadata } = execution;
    const columnTypes = metadata.columnTypes;
    const columnNames = metadata.columnKeys;
    const columnRegistry = new Map();
    columnNames.forEach(name => {
        columnRegistry.set(name, columnTypes[name]);
    });

    return columnRegistry;
};

const getExecutionDuration = (executionStartedAt?: string, executionEndedAt?: string) => {
    let duration = '';

    if (!executionStartedAt || !executionEndedAt) return '-';

    const startTime = fromUnixTime(+executionStartedAt / 1000);
    const endTime = fromUnixTime(+executionEndedAt / 1000);
    const totalSeconds = differenceInSeconds(endTime, startTime);

    const hr = Math.floor(totalSeconds / 3600);
    const min = Math.floor((totalSeconds % 3600) / 60);
    const sec = totalSeconds % 60;

    if (hr > 0) {
        duration += `${hr}h `;
    }
    if (min > 0) {
        duration += `${min}m `;
    }
    if (sec > 0) {
        duration += `${sec}s`;
    }

    if (!duration && sec < 1) {
        duration += '<1s';
    }

    return duration;
};

const getExecutionColumnType = (columnType?: StudioExecutionColumnTypes) => {
    if (!columnType) return undefined;

    return STUDIO_EXECUTION_COL_TYPE_MAP?.[columnType];
};

const getExecutionColumnKeysByType = (columnTypes: any, type?: 'date' | 'number' | 'string') => {
    return Object.keys(
        pickBy(columnTypes, value => {
            return !type ? true : getExecutionColumnType(value) === type;
        }),
    );
};

export {
    createColumnRegistry,
    getExecutionColumnKeysByType,
    getExecutionColumnType,
    getExecutionDuration,
    getExecutionViews,
    getTimeseriesColumn,
    INTERVAL_BOUNDS,
};
