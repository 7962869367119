import { forwardRef, LegacyRef } from 'react';
import * as styles from '../icon.styles';
import type { IconProps } from '../icon.model';
const QueriesIcon = forwardRef(
    ({ title, titleId, color, borderRadius, ...props }: IconProps, ref?: LegacyRef<SVGSVGElement>) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 15 14"
            width={props.width}
            height={props.height}
            role="img"
            aria-hidden="true"
            className={styles.icon({
                ...props,
                borderRadius,
                color,
            })}
            ref={ref}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.333}
                d="M4.97 9v1.333m2.666-4v4m2.667-6.666v6.666M4.836 13h5.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108V4.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C12.116 1 11.556 1 10.436 1h-5.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874c-.218.428-.218.988-.218 2.108v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.428.218.988.218 2.108.218"
            />
        </svg>
    ),
);
QueriesIcon.displayName = 'QueriesIcon';
export default QueriesIcon;
