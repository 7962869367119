import { defineEndpoints } from '../../../config/define-endpoints';
import { DataWithMeta, OrSymbol, PaginatedRequest } from '../../../config/type-helpers';
import type { ExecutionMetadata, StudioExecutionState } from '../execution/execution.model';

enum StudioQueryFilterKey {
    Visualization = 'visualizationTypes',
    Dashboard = 'dashboardIds',
    Author = 'userIds',
    Status = 'statuses',
    Order = 'order',
    Filter = 'filter',
}

type QueryShape = {
    id: string;
    userId: string;
    lastExecutionId: string;
    sql: string;
    slug: string;
    title: string;
    updatedAt: number;
    createdAt: number;
    userEmail: string;
    userName: string;
    executionState: StudioExecutionState;
    executionMetadata: ExecutionMetadata;
    executionStartedAt: string;
    executionEndedAt: string;
};

type GetQueryRequest = {
    id: string;
};

type GetQueryResponse = DataWithMeta<QueryShape>;

type GetQueriesResponse = DataWithMeta<QueryShape[]>;
type GetQueriesRequest = PaginatedRequest<
    QueryShape,
    {
        userIds?: OrSymbol<string[] | string>;
        dashboardIds?: OrSymbol<string[] | string>;
        statuses?: OrSymbol<string[] | string>;
        filter?: string;
    }
>;

type PostCreateQueryRequest = {
    title: string;
    sql: string;
    userId?: string;
};

type PostCreateQueryResponse = DataWithMeta<{
    message: string;
    id: string;
    slug: string;
}>;

type PatchUpdateQueryRequest = {
    id: string;
    title: string;
    sql?: string;
};

type DeleteQueryResponse = DataWithMeta<{ response: string; status: boolean }>;
type DeleteQueryRequest = { id: string };

const STUDIO_QUERIES_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        QUERY: template('/api/v1/studio/query/{id}'),
        QUERIES: template('/api/v1/studio/queries'),
        EXPORT_QUERY: template('/api/v1/studio/export/{id}'),
    },
    POST: {
        QUERY: template('/api/v1/studio/query'),
    },
    PATCH: {
        QUERY: template('/api/v1/studio/query/{id}'),
    },
    DELETE: {
        QUERY: template('/api/v1/studio/query/{id}'),
    },
}));

export type {
    DeleteQueryRequest,
    DeleteQueryResponse,
    GetQueriesRequest,
    GetQueriesResponse,
    GetQueryRequest,
    GetQueryResponse,
    PatchUpdateQueryRequest,
    PostCreateQueryRequest,
    PostCreateQueryResponse,
};

export { STUDIO_QUERIES_ENDPOINTS, StudioQueryFilterKey };
