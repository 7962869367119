import React from 'react';
import { assetQueries } from '@studio/api/assets/queries';
import { StudioRoutePath } from '@studio/models/routes';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { NO_ASSET_CONFIG, StudioDashboardStatus } from '@blockworks/platform/api/research/studio/dashboard';
import { useBoolean } from '@blockworks/platform/hooks';
import { Form } from '@blockworks/ui/forms/form';
import { mutations } from '@blockworks/viz/mutations';

import { CreateEntityModal, type CreateEntityModalConsumerProps } from '../create-entity-modal';

const createDashboardSchema = yup.object({
    title: yup.string().required('A title is required.'),
    assetId: yup.string().required('An asset is required.'),
});

const CreateDashboardModal = ({ hideTrigger = false, ...controlledModal }: CreateEntityModalConsumerProps) => {
    const uncontrolledModal = useBoolean(false);
    const modal = controlledModal.value !== undefined ? controlledModal : uncontrolledModal;

    const router = useRouter();
    const { control, handleSubmit, formState, reset } = Form.use({
        shouldUseNativeValidation: false,
        resolver: Form.resolvers.yup(createDashboardSchema),
    });

    const { data: assetOptions } = assetQueries.getAssets.use({
        variables: { isSupported: true },
        select: assetQueries.getAssets.select.optionsForDashboard,
    });

    const mutation = mutations.createDashboard.use();
    const handleOnSubmit = handleSubmit(async ({ title, assetId }) => {
        try {
            const res = await mutation.mutateAsync({
                title,
                status: StudioDashboardStatus.Draft,
                assetId,
            });
            if (res?.data?.id) {
                modal.setFalse();
                router.push(StudioRoutePath.EditDashboard({ id: res.data.id }));
            } else {
                console.error('ID was not returned from create-dashboard:', res);
            }
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    });

    const handleOnClose = () => reset({ title: '', assetId: '' });

    return (
        <>
            <CreateEntityModal.Trigger hide={hideTrigger} onClick={modal.setTrue}>
                New Dashboard
            </CreateEntityModal.Trigger>
            <CreateEntityModal
                open={modal.value}
                onOpenChange={modal.setValue}
                onClose={handleOnClose}
                title="New Dashboard"
                errorMessage={mutation?.error?.message}
                submitProps={{
                    loading: formState.isSubmitting,
                    loadingText: 'Creating Dashboard',
                    label: 'Create Dashboard',
                    onSubmit: handleOnSubmit,
                }}
            >
                <Form.Input label="Title" name="title" control={control} />
                <Form.Select
                    label="Asset"
                    name="assetId"
                    control={control}
                    helpText="The asset your Dashboard relates to."
                    options={assetOptions ?? []}
                    defaultValue={NO_ASSET_CONFIG.value}
                />
            </CreateEntityModal>
        </>
    );
};

export { CreateDashboardModal };
