import { tryJsonParse } from '../../../../utils';
import { StudioVizConfig } from '../visualizations';

import { GetDashboardResponseRaw } from './dashboard.model';
import { LayoutConfig, LayoutItemType } from './dashboard-layout.model';

const getVizIdsFromLayout = (newLayouts?: LayoutConfig) => {
    if (!newLayouts) return undefined;

    const layoutVizIds =
        newLayouts?.lg?.filter(item => item.data.type !== LayoutItemType.Markdown)?.map(item => item.data.id) ?? [];

    return layoutVizIds?.length > 0 ? layoutVizIds : undefined;
};

const parseDashboardVisualizationConfigs = (visualizations: GetDashboardResponseRaw['data']['visualizations']) => {
    if (!visualizations?.length) return visualizations as [];
    return visualizations.map(viz => ({
        ...viz,
        config: tryJsonParse<StudioVizConfig<any>>(viz.config).parsedJson,
    }));
};

export { getVizIdsFromLayout, parseDashboardVisualizationConfigs };
