import type { GetExecutionResponse } from '@blockworks/platform/api/research/studio/execution';

import { StudioVisualizationType, StudioVizConfig } from '../models';

const makeCounterConfig = <T extends GetExecutionResponse['data']>(
    _?: T,
): StudioVizConfig<StudioVisualizationType.Counter> => {
    return {
        type: StudioVisualizationType.Counter,
        options: {
            groups: [],
        },
        features: { export: false },
        parameters: {},
    };
};

export { makeCounterConfig };
